import { Injectable } from '@angular/core';

@Injectable()
export class HelperService {
  public decimalNumber(value: any): number {
    let v = Number.parseFloat(value);
    if (Number.isNaN(v)) {
      v = 0;
    }
    const roundAmount = Math.round(v * 100) / 100;
    return parseFloat(roundAmount.toFixed(2));
  }
}
